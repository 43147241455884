<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1443 1181" fill="none" class="h-full">
        <g filter="url(#filter0_f_2807_13735)">
            <path
                d="M823.062 733C817.276 733.579 810.682 738.635 805.557 740.965C794.475 746.002 784.199 752.372 773.349 757.857C755.768 766.744 737.04 773.559 720.484 784.289C692.433 802.47 660.28 812.773 634.011 834.177C621.984 843.977 607.879 851.974 597.776 863.76C592.704 869.678 593.05 880.404 593.05 887.742C593.05 895.759 591.988 904.527 594.276 912.248C596.348 919.243 602.984 918.047 609.942 917.15C631.28 914.396 651.954 904.57 671.821 896.844C690.544 889.563 707.655 884.083 727.399 880.302C750.086 875.958 770.528 878.579 793.129 881.09C802.658 882.149 820.666 886.826 827.001 894.568C833.476 902.483 827.771 905.119 820.524 911.898C797.862 933.098 771.025 951.64 745.079 968.526C723.086 982.838 701.685 996.174 678.211 1007.91C669.893 1012.07 662.022 1016.89 653.616 1020.95C644.431 1025.39 634.448 1027.54 625.259 1031.98C609.652 1039.52 600.117 1050.53 587.099 1061.56C580.926 1066.8 578.032 1071.27 585.173 1076.09C592.701 1081.18 617.823 1080.26 596.726 1090.62C579.456 1099.1 549.116 1111.4 544.562 1132.81C537.438 1166.29 599.972 1181.98 622.283 1183.22C648.736 1184.69 663.957 1166.43 679.348 1148.91C704.875 1119.85 737.489 1099.65 769.235 1078.11C796.957 1059.29 827.697 1050.14 859.997 1041.78C875.608 1037.74 892.314 1032.66 908.31 1038.11C915.536 1040.57 920.524 1045.54 925.64 1050.97C937.278 1063.33 948.468 1076.25 958.986 1089.57C971.465 1105.38 984.379 1125.15 1000.3 1137.88C1001.9 1139.17 1002.13 1144.9 1001.96 1146.64C1001.11 1155.3 976.313 1158.44 970.627 1160.2C928.423 1173.25 889.332 1194.1 847.744 1208.69C839.62 1211.54 827.676 1214.19 823.062 1222.43C817.76 1231.9 816.169 1248.73 819.124 1259.37C820.674 1264.95 818.26 1271.68 821.487 1276.52"
                stroke="#0545C5" stroke-opacity="0.8" stroke-width="243.472" stroke-linecap="round" />
        </g>
        <defs>
            <filter id="filter0_f_2807_13735" x="-277.785" y="-88.7451" width="2101.53" height="2187.02"
                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur stdDeviation="350" result="effect1_foregroundBlur_2807_13735" />
            </filter>
        </defs>
    </svg>
</template>
